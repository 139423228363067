
import { WorthDate } from '@/composables/types';
import Currency from '@/components/General/Currency.vue';
import { defineComponent, PropType, computed } from 'vue';
import { format } from 'date-fns';

interface Props {
  netWorth: WorthDate[];
}

export default defineComponent({
  name: 'Net Worth Table',
  components: { Currency },
  props: {
    netWorth: {
      type: Array as PropType<WorthDate[]>,
      default: () => [],
    },
  },
  setup(props: Props) {
    const rows = computed(() =>
      props.netWorth
        .map((item, index, list): any => {
          let previous = 0;
          if (index > 0) {
            previous = item.worth - list[index - 1].worth;
          }
          return Object.assign({}, item, {
            date: format(new Date(item.date), 'yyyy MMM'),
            previous,
          });
        })
        .reverse(),
    );

    return { rows };
  },
});
