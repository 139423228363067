
import { isBetween } from '@/services/helper';
import useYnab from '@/composables/ynab';
import { computed, defineComponent, PropType, ref } from 'vue';
import { addDays } from 'date-fns';
import { formatToTimeZone as format } from 'date-fns-timezone';

interface Props {
  dates: string[];
  startDate: string;
  endDate: string;
}

export default defineComponent({
  name: 'Date Select',
  props: {
    dates: { type: Array as PropType<string[]>, default: () => [] },
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
  },
  setup(props: Props, { emit }) {
    const { state, setBudgetStartDate, setBudgetEndDate } = useYnab();
    const firstDate = computed(() => props.dates[0]);
    const lastDate = computed(() => props.dates[props.dates.length - 1]);

    const selectedStartDate = ref(props.startDate);
    const selectedEndDate = ref(props.endDate);

    const startDateOptions = computed(() =>
      props.dates.filter(date => {
        const current = new Date(date);
        const start = new Date(firstDate.value);
        const end = addDays(new Date(selectedEndDate.value), -1);
        return isBetween(current, start, end);
      }),
    );

    const endDateOptions = computed(() =>
      props.dates.filter(date => {
        const current = new Date(date);
        const start = addDays(new Date(selectedStartDate.value), 1);
        const end = new Date(lastDate.value);
        return isBetween(current, start, end);
      }),
    );

    function formatDate(date: string) {
      return format(new Date(date), 'MMM YYYY', { timeZone: 'UTC' });
    }

    function dateRangeSelected() {
      const budget = {
        selectedStartDate: selectedStartDate.value,
        selectedEndDate: selectedEndDate.value,
        id: state.selectedBudgetId,
      };
      setBudgetStartDate(budget);
      setBudgetEndDate(budget);
      emit('dateRangeSelected');
    }

    return {
      firstDate,
      lastDate,
      startDateOptions,
      endDateOptions,
      formatDate,
      dateRangeSelected,
      selectedStartDate,
      selectedEndDate,
    };
  },
});
