
import { WorthDate } from '@/composables/types';
import { formatDate } from '@/services/helper';
import Currency from '@/components/General/Currency.vue';
import { computed, defineComponent, PropType } from 'vue';

interface Props {
  selectedItem: WorthDate;
  forecast: boolean;
}

export default defineComponent({
  name: 'Current Net Worth Summary',
  components: { Currency },
  props: {
    selectedItem: {
      type: Object as PropType<WorthDate>,
      required: true,
    },
    forecast: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: Props) {
    const difference = computed(() => {
      if (props.selectedItem.previous !== undefined) {
        return props.selectedItem.worth - props.selectedItem.previous.worth;
      } else return null;
    });

    const date = computed(() => formatDate(props.selectedItem.date));

    const worth = computed(() => props.selectedItem.worth);

    return { difference, date, worth };
  },
});
