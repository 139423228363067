
import { computed, defineComponent, ref, watch } from 'vue';
import DateSelect from '@/components/General/DateSelect.vue';
import CurrentNetWorthSummary from '@/components/General/CurrentNetWorthSummary.vue';
import NetWorthGraph from '@/components/Graphs/NetWorth.vue';
import NetWorthStats from '@/components/Stats/NetWorth.vue';
import NetWorthTable from '@/components/Tables/NetWorth.vue';
import ReloadIcon from '@/components/Icons/ReloadIcon.vue';
import MonthlyAverage from '@/components/Graphs/MonthlyAverage.vue';
import useYnab from '@/composables/ynab';
import { getData as getDummyData } from '@/composables/dummyGraph';
import { WorthDate } from '@/composables/types';
import useSettings from '@/composables/settings';

export default defineComponent({
  name: 'Net Worth',
  components: {
    DateSelect,
    ReloadIcon,
    MonthlyAverage,
    NetWorthGraph,
    NetWorthStats,
    NetWorthTable,
    CurrentNetWorthSummary,
  },
  setup() {
    const {
      getNetWorth,
      getForecast,
      getCombined,
      getSelectedStartDate,
      getSelectedEndDate,
      getFilteredDateRange,
      loadMonthlyData,
      createDateList,
      state,
    } = useYnab();

    const { isDummy: isDummyFlag } = useSettings();

    const selectedItem = ref<WorthDate | null>(null);
    const reloadAction = ref<Function>();
    const reloadText = ref<string>();
    const netWorth = ref<WorthDate[] | null>(null);
    const dateList = ref<string[]>();
    const startDate = ref<string>();
    const endDate = ref<string>();

    function dateHighlighted(item: WorthDate) {
      selectedItem.value = item;
    }

    function useRealData() {
      const data = getFilteredDateRange(getNetWorth.value) ?? [];
      const dates = createDateList(getNetWorth.value);
      netWorth.value = data;
      dateList.value = dates;
      reloadAction.value = loadMonthlyData;
      reloadText.value = 'Refresh';
      startDate.value = getSelectedStartDate.value ?? '';
      endDate.value = getSelectedEndDate.value ?? '';
      dateHighlighted(data[data.length - 1]);
    }

    function useDummyData() {
      const data = getDummyData();
      const dates = createDateList(data);
      netWorth.value = data;
      dateList.value = dates;
      reloadAction.value = useDummyData;
      reloadText.value = 'Randomize Dummy Data';
      startDate.value = data[0].date;
      endDate.value = data[data.length - 1].date;
      dateHighlighted(data[data.length - 1]);
    }

    function reload() {
      isDummyFlag.value ? useDummyData() : useRealData();
    }

    watch(
      () => isDummyFlag.value,
      () => reload(),
    );

    reload();

    const ready = computed(() => netWorth.value && netWorth.value.length > 0);
    const loadingStatus = computed(() => state.loadingNetWorthStatus);
    const rotate = computed(() => state.loadingNetWorthStatus === 'loading');

    watch(
      () => loadingStatus.value,
      () => {
        if (loadingStatus.value === 'complete') reload();
      },
    );

    return {
      netWorth,
      dateList,
      reloadAction,
      reloadText,
      forecast: getForecast,
      combined: getCombined,
      loading: state.loadingStatus,
      loadingNetWorth: state.loadingNetWorthStatus,
      loadingForecast: state.loadingForecastStatus,
      startDate,
      endDate,
      dateHighlighted,
      selectedItem,
      ready,
      rotate,
      reload,
    };
  },
});
